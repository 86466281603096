import React from 'react'
import PropTypes from 'prop-types'

import SkeletonAnimationStyled from './styled'

class SkeletonAnimation extends React.PureComponent {
  render() {
    return <SkeletonAnimationStyled id={this.props.id} data-test={this.props.id} className={this.props.className} style={this.props.style} />
  }
}

SkeletonAnimation.defaultProps = {
  id: 'skeleton-loading',
  style: {},
}

SkeletonAnimation.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  style: PropTypes.object,
}

export default SkeletonAnimation
