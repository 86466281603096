import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.08);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.4) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1.2s ease-in-out infinite;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(135%);
    }
  }
`
